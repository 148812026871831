import React from "react";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../layout";
import HoloCal from "../components/HoloCal";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import "./b16-tomorrow-dark.css";
import "./post.css";
import headerSrc from "../images/docks.png"
import * as moment from 'moment';


export default class PostTemplate extends React.Component {
  render() {
      
      const { data, pageContext } = this.props
      const { slug } = pageContext
      const postNode = data.airtable
      const post = postNode.data
      const author = post.author ? post.author[0].data : ""
      
    if (!post.id) {
      post.id = slug
    }
    
    if (!post.category) {
      post.category = config.postDefaultCategoryID
    }
        
    const headerLinks = <h3 className="events-backlink"><Link to="/events">EVENTS</Link></h3>
    const whenFormatted = moment(post.When).local().format("MMMM Do Y hh:mm a")
    const when = post.whenDiff > -2000 ? whenFormatted : "DATE TBA";


    const PageHero = () => {
        return ( <div className='title_image' style={{
          backgroundImage: `url(${headerSrc})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          }}>
          {headerLinks}
          <h2 className="title">{post.title}</h2>
          <h3 className="event-page-date">{when} @ {post.Where}</h3>
        </div>
        )
    }

    let flyer = ''
    if (post.image && post.image.localFiles && post.image.localFiles[1]) {
        flyer = post.image.localFiles[1].childImageSharp.gatsbyImageData
    }

    return (
      <>
      <Helmet>
        <title>{`${post.title} | ${config.siteTitle}`}</title>
      </Helmet>
      <Layout>
        <div className="index-container event-page">
          <SEO postPath={this.props.location.pathname} postNode={postNode} postSEO />
          <div>
            <PageHero />
            <div dangerouslySetInnerHTML={{ __html: post.postMarkdown.childMarkdownRemark.html }} />
            {author}
            <GatsbyImage image={flyer} alt="flyer" />
            <HoloCal/>
          </div>
        </div>
      </Layout>
      </>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $dateFormat: String) {
    airtable(data: {slug: {eq: $slug}}) {
      table
      data {
        title
        slug
        whenDiff: When(difference: "days")
        When
        Where
        date(formatString: $dateFormat)
        postMarkdown {
          childMarkdownRemark {
            html
            excerpt(format: PLAIN)
            timeToRead
          }
        }
        image {
            localFiles {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
              }
            }
          }
      }
    }
  }
`;
